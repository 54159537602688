.admin-nav-wrapper {
  width: 100%;
  background: linear-gradient(135deg, #3a6186, #4676b3);
  padding: 1rem 0;
  margin-bottom: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.admin-nav {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
}

.nav-links {
  display: flex;
  gap: 0.5rem;
  z-index: 1001;
  position: relative;
}

.nav-link {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  padding: 0.7rem 1.2rem;
  border-radius: 6px;
  transition: all 0.3s;
  font-weight: 500;
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;
  cursor: pointer !important;
  z-index: 100;
  pointer-events: auto !important;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  height: 3px;
  background-color: white;
  border-radius: 3px 3px 0 0;
}

.admin-nav-links {
  display: flex;
  gap: 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-nav-links a {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: background-color 0.3s;
}

.admin-nav-links a:hover {
  background: rgba(255, 255, 255, 0.1);
}

.admin-nav-links a.active {
  background: rgba(255, 255, 255, 0.2);
  font-weight: bold;
}

.admin-nav-links svg {
  width: 16px;
  height: 16px;
}

.logout-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #c82333;
}

@media (max-width: 600px) {
  .admin-nav-links {
    flex-direction: column;
  }
}
