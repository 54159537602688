.admin-menu-page {
  padding: 20px;
  background: #f5f5f5;
  min-height: 100vh;
}

.admin-menu-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-form {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  display: grid;
  gap: 15px;
  max-width: 600px;
}

.menu-form input,
.menu-form select,
.menu-form textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.menu-form textarea {
  resize: vertical;
  min-height: 100px;
}

.menu-form button {
  padding: 12px;
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.menu-form button:hover:not(:disabled) {
  background: #45a049;
}

.menu-form button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.image-upload {
  margin: 15px 0;
}

.file-input {
  width: 100%;
  padding: 10px;
  border: 2px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.file-input:hover {
  border-color: #999;
}

.image-preview {
  margin-top: 10px;
  max-width: 200px;
  border-radius: 4px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  height: auto;
  display: block;
}

.menu-items-list {
  margin-top: 30px;
}

.category-section {
  margin-bottom: 40px;
}

.category-section h3 {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #333;
  color: #333;
}

.items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.menu-item-card {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
}

.item-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-content {
  padding: 15px;
}

.item-content h4 {
  margin: 0 0 10px 0;
  color: #333;
}

.price {
  font-weight: bold;
  color: #2c3e50;
  margin: 5px 0;
}

.weight {
  color: #666;
  font-size: 0.9em;
  margin: 5px 0;
}

.description {
  color: #666;
  margin: 10px 0;
  line-height: 1.4;
}

.delete-button {
  margin-top: 10px;
  padding: 8px 16px;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.delete-button:hover {
  background: #c82333;
}

@media (max-width: 768px) {
  .items-grid {
    grid-template-columns: 1fr;
  }

  .menu-form {
    padding: 15px;
  }
}

.loading-button {
  opacity: 0.7;
  cursor: not-allowed;
}

.loading-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.menu-form button {
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.menu-form button:hover:not(:disabled) {
  background-color: #0056b3;
}

.menu-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
