:root {
  --primary-color: #e74c3c;
  --primary-dark: #c0392b;
  --secondary-color: #2ecc71;
  --accent-color: #3498db;
  --background-light: #f8f9fa;
  --background-dark: #343a40;
  --text-dark: #343a40;
  --text-light: #f8f9fa;
  --text-muted: #6c757d;
  --border-radius: 10px;
  --box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  --animation-speed: 0.3s;
}

.offers-page {
  font-family: "Nunito", "Segoe UI", Roboto, sans-serif;
  color: var(--text-dark);
  background-color: var(--background-light);
  padding-top: 50px;
  padding-bottom: 50px;
}

/* Hero-sektion med bakgrundsbild */
.offers-hero {
  background-image: linear-gradient(135deg, #e74c3c, #c0392b);
  background-size: cover;
  background-position: center;
  color: white;
  padding: 80px 0;
  text-align: center;
  position: relative;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero-title {
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: 800;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.8s ease-out;
}

.hero-subtitle {
  font-size: 1.3rem;
  margin-bottom: 30px;
  line-height: 1.5;
  font-weight: 300;
  animation: fadeIn 0.8s ease-out 0.3s forwards;
  opacity: 0;
}

/* Container för huvudinnehållet */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 20px;
}

.main-content {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}

/* Kontrollpanel för erbjudanden */
.offers-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  padding: 15px 20px;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.offers-info {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.1rem;
  color: var(--text-muted);
}

.offers-info svg {
  color: var(--accent-color);
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Stiliserad toggle-switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: var(--accent-color);
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

/* Grid för erbjudanden */
.offers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  animation: fadeIn 0.5s ease-out;
  margin-top: 40px;
}

/* Erbjudandekort med förbättrad styling */
.offer-card {
  background-color: white;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  transition: transform var(--animation-speed) ease,
    box-shadow var(--animation-speed) ease;
  position: relative;
  padding-top: 25px;
  animation: slideUp 0.5s ease-out;
  margin-bottom: 30px;
}

.offer-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.offer-card.expired {
  opacity: 0.7;
}

.offer-image {
  height: 200px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #f8f8f8;
}

.offer-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
  display: block;
}

.offer-card:hover .offer-image img {
  transform: scale(1.05);
}

.placeholder-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #999;
  padding: 20px;
}

.placeholder-image svg {
  font-size: 50px;
  margin-bottom: 10px;
}

.offer-content {
  padding: 20px;
  position: relative;
  margin-top: 10px;
}

.offer-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: var(--text-dark);
  font-weight: 700;
  padding-right: 40px;
}

/* Dölj beskrivningstexten i erbjudandekorten på hemsidan */
.offer-description {
  display: none !important;
}

.valid-until {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
  color: var(--text-muted);
  margin-bottom: 15px;
}

.offer-price-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}

.price-tag {
  display: flex;
  flex-direction: column;
}

.price {
  font-weight: bold;
  font-size: 1.5rem;
  color: var(--primary-color);
}

.regular-price {
  font-size: 1rem;
  color: var(--text-muted);
  text-decoration: line-through;
  margin-left: 10px;
}

.savings {
  color: var(--secondary-color);
  font-size: 0.9rem;
  font-weight: 600;
}

/* Badge för utgångna erbjudanden */
.expired-badge {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: var(--text-muted);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 600;
  z-index: 2;
}

/* Badge för rabatter */
.discount-badge {
  position: absolute;
  top: -25px;
  left: 15px;
  background-color: var(--secondary-color);
  color: white;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.1rem;
  font-weight: 700;
  z-index: 2;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(25px);
}

/* Dölj hjärtikonen/knappen */
.favorite-button {
  display: none !important;
}

/* Meddelande när inga erbjudanden finns */
.no-offers {
  text-align: center;
  padding: 60px 20px;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.no-offers-icon {
  font-size: 60px;
  color: var(--text-muted);
  margin-bottom: 20px;
}

.no-offers h2 {
  margin-bottom: 15px;
  color: var(--text-dark);
}

.no-offers p {
  color: var(--text-muted);
  font-size: 1.1rem;
}

/* Laddningsanimation */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid var(--accent-color);
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Informationssektion */
.offers-info-section {
  display: none !important;
}

/* Om ovanstående inte fungerar, försök med dessa mer specifika selektorer */
section.offers-info-section,
div.offers-info-section,
[class*="info-section"],
div:has(> h2:contains("Om våra erbjudanden")),
div[style*="background-color"]:has(h2:first-child),
.main-content > div:last-child,
footer ~ div:not(footer) {
  display: none !important;
}

/* Se till att footer inte påverkas om den kommer efter denna sektion */
footer {
  display: block !important;
}

/* Responsiv design */
@media (max-width: 992px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1.1rem;
  }

  .offers-grid {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .offers-hero {
    padding: 60px 0;
  }

  .hero-title {
    font-size: 2rem;
  }

  .offers-controls {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }

  .offers-grid {
    grid-template-columns: 1fr;
    max-width: 450px;
    margin: 0 auto;
  }

  .main-content {
    padding: 30px 0;
  }

  .offers-info-section {
    padding: 40px 0;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 1.8rem;
  }

  .offer-image {
    height: 180px;
  }

  .price {
    font-size: 1.3rem;
  }

  .offer-title {
    font-size: 1.3rem;
  }
}

/* Animation för loading card skeleton */
@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.6;
  }
}

.skeleton {
  animation: pulse 1.5s infinite;
  background-color: #f0f0f0;
}

/* Lägg till följande CSS-animationer */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Lägg till detta i din admin CSS-fil (troligen AdminOffers.css eller liknande) */
.form-group label[for="description"],
.form-group textarea#description,
.form-group div:has(> #description),
.form-control[name="description"],
textarea[placeholder*="beskrivning" i],
textarea[placeholder*="Beskriv" i],
div:has(> textarea[placeholder*="beskrivning" i]),
.description-field {
  display: none !important;
}

/* Dölj antalet erbjudanden text */
.offers-info,
.offers-count,
div:has(> span.offers-count),
*[class*="offers-count"] {
  display: none !important;
}

/* Dölj "Visa utgångna erbjudanden" toggle */
.toggle-container,
.toggle-expired,
label.toggle-expired,
div:has(> label.toggle-expired),
div:has(> input[type="checkbox"][checked]),
.toggle-switch,
*[class*="toggle"] {
  display: none !important;
}

/* Om det finns en container som innehåller båda dessa element */
.offers-controls,
.offers-header,
.filter-options,
div:has(> .toggle-container):has(> .offers-info) {
  display: none !important;
}

/* Öka avståndet mellan "Veckans erbjudanden" och erbjudandelistan */
.offers-header,
h1 + .offers-grid,
.offers-hero + .container,
.main-content > .container:first-child,
.offers-page > h1 {
  margin-bottom: 40px;
}

/* Lägg till mer utrymme före erbjudandelistan */
.offers-grid,
.offers-list,
.main-content > .container:nth-child(2) {
  margin-top: 40px;
}

/* Öka avståndet mellan varje erbjudande för mer luftig layout */
.offer-card {
  margin-bottom: 30px;
}

/* Förbättra det övergripande innehållsutrymmet */
.offers-page {
  padding: 30px 0;
}

/* Förbättra sektionsavstånden generellt */
.main-content {
  padding-top: 40px;
  padding-bottom: 60px;
}

/* Dölj informationssektionen med text om erbjudanden */
.offers-info-section,
.info-content,
.info-section,
div:has(> h2:contains("Om våra erbjudanden")),
section:has(> h2:contains("Om våra erbjudanden")),
div:has(> div > h2:contains("Om våra erbjudanden")) {
  display: none !important;
}

/* För att säkerställa att även sektioner med liknande innehåll döljs */
div:has(> p:contains("veckoerbjudanden")),
section:has(> p:contains("veckoerbjudanden")),
div:has(> p:contains("sociala medier")),
section:has(> p:contains("sociala medier")) {
  display: none !important;
}

/* Dölj bakgrunden om den har en separat klass */
.dark-background,
.gray-background,
.background-dark,
.info-background {
  display: none !important;
}

/* CSS för att dölja "Meny"-länken i admin-navigationen */
/* Lägg till denna kod i din admin CSS-fil */

/* Dölj menylänken baserat på text */
.admin-nav a:has(span:contains("Meny")),
.admin-nav a:has(span:contains("Menu")),
.admin-nav li:has(a:contains("Meny")),
.admin-nav li:has(a:contains("Menu")),
.admin-sidebar a:contains("Meny"),
.admin-sidebar a:contains("Menu"),
nav a[href*="menu"],
nav a[href*="meny"],
a[href*="/admin/menu"],
a[href*="/admin/meny"] {
  display: none !important;
}

/* Större stjärnor överallt */
.review-rating span,
.review-rating .star,
.star.filled,
.star:not(.filled),
.review-card .review-rating span {
  font-size: 28px !important; /* Öka storlek för alla stjärnor */
  line-height: 1 !important;
  display: inline-block !important;
}

/* Stjärnor i popup/modal */
.popup-rating .star,
.review-modal .star,
.modal-review-rating .star,
.popup-comment .review-rating span {
  font-size: 36px !important; /* Ännu större i popups */
}

/* Förbättra stjärnorna på mobila enheter */
@media (max-width: 767px) {
  .review-rating span,
  .review-rating .star,
  .star.filled,
  .star:not(.filled) {
    font-size: 24px !important; /* Något mindre på mobil, men fortfarande stora */
  }
}

/* Säkerställ att alla stjärnor har exakt samma storlek oavsett fyllning */
.review-rating span,
.star,
.star.filled,
.star:not(.filled),
.review-card .review-rating span,
span[class*="star"],
.review-rating *,
.popup-rating * {
  font-size: 28px !important; /* Samma storlek för alla stjärnor */
  line-height: 1 !important;
  display: inline-block !important;
  vertical-align: middle !important;
  font-family: Arial, sans-serif !important; /* Konsekvent typsnitt */
  transform: none !important; /* Förhindra eventuella transformationer */
  width: auto !important; /* Låt stjärnorna ta naturlig bredd */
  height: auto !important;
}

/* Specifikt rikta in sig på stjärnor med färg */
.star.filled,
.review-rating span:first-child,
.review-rating span[style*="color"],
span[style*="FFD700"] {
  font-size: 28px !important; /* Exakt samma värde som ovan */
  transform: none !important;
  padding: 0 !important;
  margin: 0 2px 0 0 !important; /* Konsekvent marginal */
}

/* Tillfällig debuggingkod - ta bort efter användning */
.star.filled {
  border: 1px solid red !important;
}

.star:not(.filled) {
  border: 1px solid blue !important;
}

/* Extremt kraftfull CSS för att överskriva alla befintliga stilar */
.review-rating span,
.review-rating *,
span.star,
span[class*="star"],
.star.filled,
.star:not(.filled),
span[style*="color"],
*[class*="rating"] *,
.review-card .review-rating *,
.review-header .review-rating * {
  font-size: 32px !important; /* Mycket större stjärnor */
  line-height: 1 !important;
  display: inline-block !important;
  vertical-align: middle !important;
  transform: scale(1.2) !important; /* Forcera skalning */
  margin: 0 3px !important;
  padding: 0 !important;
  text-shadow: none !important;
}

/* Ultra-specifik selector chain */
html
  body
  .reviews-page
  .reviews-container
  .reviews-grid
  .review-card
  .review-header
  .review-rating
  span,
html
  body
  .reviews-page
  .reviews-container
  .reviews-grid
  .review-card
  .review-header
  .review-rating
  .star,
.review-rating span[class],
.review-rating > span,
span.star,
span.star.filled,
span.star:not(.filled) {
  font-size: 32px !important;
  line-height: 1.2 !important;
  display: inline-block !important;
  width: auto !important;
  height: auto !important;
  transform: scale(1.2) !important;
  -webkit-text-size-adjust: none !important;
  text-rendering: geometricPrecision !important;
}

/* Nollställ först alla stjärnstilar */
.review-rating span,
.star,
.review-rating *,
.review-card .review-rating span {
  font-size: 28px !important;
  line-height: 1 !important;
  display: inline-block !important;
  vertical-align: middle !important;
  transform: none !important;
  margin: 0 2px !important;
  padding: 0 !important;
  text-shadow: none !important;
  width: auto !important;
  height: auto !important;
  box-sizing: content-box !important;
}

/* Specifikt mål för gula/ifyllda stjärnor */
.star.filled,
.review-rating span:first-child,
.review-rating span:nth-child(1),
.review-rating span:nth-child(2),
.review-rating span:nth-child(3),
.review-rating span:nth-child(4),
.review-rating span:nth-child(5) {
  font-size: 28px !important;
  transform: none !important;
  display: inline-block !important;
  color: #ffd700 !important;
}

/* Specifikt mål för tomma/icke-ifyllda stjärnor */
.star:not(.filled),
.review-rating span:nth-child(n + 6) {
  font-size: 28px !important;
  transform: none !important;
  display: inline-block !important;
  color: #d3d3d3 !important;
}

/* Specifik korrigering om stjärnorna implementeras med Unicode-tecken */
.review-rating,
.review-header .review-rating {
  letter-spacing: 0 !important;
  font-family: Arial, sans-serif !important;
}

/* Mindre storlek på recensionselement */
.admin-stat-card,
.stat-title,
.stat-value {
  font-size: 14px !important;
  padding: 10px !important;
}

/* Mindre stjärnor i betygsöversikten */
.stat-stars .star,
.admin-stat-card .star,
.genomsnittligt-betyg .star {
  font-size: 18px !important;
  margin-right: 1px !important;
}

/* Minska stjärnorna i betygsfördelningen */
.betygsfordelning .star,
[class*="betyg"] .star {
  font-size: 16px !important;
}

/* Justera storlek på siffror i betygsfördelningen */
.betygsfordelning [class*="antal"],
.betygsfordelning [class*="procent"] {
  font-size: 14px !important;
}

/* Mindre sidhuvud */
.reviews-header h1,
.admin-reviews h1,
h1[class*="recensioner"] {
  font-size: 20px !important;
  margin-bottom: 15px !important;
}

/* Mindre textfält för sökning */
input[type="search"],
input[type="text"][placeholder*="Sök"] {
  font-size: 14px !important;
  height: 32px !important;
}

/* Mindre knappar */
button.uppdatera,
button[class*="filter"] {
  font-size: 14px !important;
  padding: 6px 12px !important;
}

/* Mindre storlek på admin-panelens element */
.admin-reviews,
.admin-panel,
[class*="admin"] {
  font-size: 14px !important;
}

/* Minska rubrikstorlek i admin-panelen */
.admin-reviews h1,
.admin-panel h1,
[class*="admin"] h1 {
  font-size: 22px !important;
  margin-bottom: 15px !important;
}

/* Minska storlek på statistikkort i admin */
.admin-stat-card,
.admin-panel .stat-card,
.admin-reviews .stat-card {
  padding: 10px !important;
  margin-bottom: 10px !important;
}

/* Mindre stjärnor i admin-panelen */
.admin-reviews .star,
.admin-panel .star,
.stat-stars .star,
.admin-stat-card .star {
  font-size: 16px !important;
  margin-right: 1px !important;
}

/* Mindre text i admin-statistik */
.stat-title,
.stat-value,
.admin-reviews .stat-title,
.admin-reviews .stat-value {
  font-size: 14px !important;
  line-height: 1.3 !important;
}

/* Minska storlek på tabeller och listvyer */
.admin-reviews table,
.admin-panel table,
.admin-table {
  font-size: 13px !important;
}

/* Kompaktare knappar i admin */
.admin-reviews button,
.admin-panel button,
[class*="admin"] button {
  font-size: 13px !important;
  padding: 5px 10px !important;
}

/* Mindre input-fält */
.admin-reviews input,
.admin-reviews select,
.admin-panel input,
.admin-panel select {
  font-size: 13px !important;
  height: 30px !important;
  padding: 4px 8px !important;
}

/* Begränsa stora stjärnor ENDAST till hemsidans recensionssida */
.reviews-page .review-rating span,
.reviews-page .star,
.reviews-page .review-card .review-rating span {
  font-size: 24px !important;
}

/* Återställ storlek för admin-panelen */
.admin-reviews .star,
.admin-reviews .review-rating span,
.admin-panel .star,
.admin-panel .review-rating span,
[class*="admin"] .star,
[class*="admin"] .review-rating span,
div[class*="admin"] .stat-stars .star {
  font-size: 14px !important;
  transform: none !important;
  margin-right: 1px !important;
}

/* Mindre statistikkort i admin */
.admin-stat-card,
.admin-panel .stat-card,
div[class*="admin"] .stat-card {
  padding: 10px !important;
  margin-bottom: 10px !important;
  font-size: 13px !important;
}

/* Mindre rubriker i admin */
.admin-reviews h1,
.admin-panel h1,
div[class*="admin"] h1 {
  font-size: 20px !important;
  margin-bottom: 15px !important;
}

/* Mindre statistiktext */
.stat-title,
.stat-value,
.admin-reviews .stat-title,
.admin-reviews .stat-value {
  font-size: 13px !important;
}

/* Mindre knappar och form-element */
.admin-reviews button,
.admin-panel button,
.admin-reviews input,
.admin-reviews select {
  font-size: 13px !important;
  padding: 4px 8px !important;
}

/* Mindre tabeller */
.admin-reviews table,
.admin-panel table,
.admin-table {
  font-size: 12px !important;
}

/* Specifik override för rating-stjärnor */
.admin-reviews .review-rating,
.admin-panel .review-rating {
  font-size: 14px !important;
  display: inline-block !important;
}

/* Ultra-aggressiv minskning av alla element i admin-panelen */
[class*="admin"] *,
[id*="admin"] *,
.admin-reviews *,
.admin-panel *,
body [class*="admin"] * {
  font-size: 13px !important;
}

/* Specifikt minska stjärnorna i admin-panelen */
[class*="admin"] .star,
[class*="admin"] .review-rating span,
.admin-reviews .star,
.admin-reviews .review-rating span,
.admin-panel .star,
.admin-panel .review-rating span,
div[class*="admin"] .stat-stars .star,
.stat-stars .star,
body .admin-reviews .star {
  font-size: 12px !important;
  transform: none !important;
  margin-right: 1px !important;
  line-height: 1 !important;
  padding: 0 !important;
}

/* Rubriker i admin med anpassade storlekar */
[class*="admin"] h1,
.admin-reviews h1,
.admin-panel h1 {
  font-size: 18px !important;
}

[class*="admin"] h2,
.admin-reviews h2,
.admin-panel h2 {
  font-size: 16px !important;
}

[class*="admin"] h3,
.admin-reviews h3,
.admin-panel h3 {
  font-size: 14px !important;
}

/* Överskrid eventuella storleksegenskaper på alla element */
[class*="admin"] *,
.admin-reviews *,
.admin-panel * {
  max-height: none !important;
  min-height: 0 !important;
  max-width: none !important;
  min-width: 0 !important;
}

/* Komprimera tabeller */
[class*="admin"] table td,
[class*="admin"] table th,
.admin-reviews table td,
.admin-reviews table th {
  padding: 4px !important;
  font-size: 12px !important;
}

/* Minska statistikkort dramatiskt */
[class*="admin"] .stat-card,
[class*="admin"] [class*="stat"],
.admin-stat-card {
  padding: 6px !important;
  margin: 5px !important;
  font-size: 12px !important;
}

[class*="admin"] .stat-title,
[class*="admin"] .stat-value,
.stat-title,
.stat-value {
  font-size: 12px !important;
  line-height: 1.2 !important;
  margin: 2px 0 !important;
}

[class*="admin"] .star {
  border: 2px solid red !important;
}
