/* Recensionsdashboard */
.reviews-dashboard {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .reviews-dashboard {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Statistikkort */
.reviews-stats-card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #eaeaea;
  transition: transform 0.2s, box-shadow 0.2s;
}

.reviews-stats-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.stat-item {
  padding: 15px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.stat-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.stat-icon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-right: 15px;
  flex-shrink: 0;
}

.total-reviews .stat-icon {
  background-color: rgba(79, 172, 254, 0.15);
  color: #4facfe;
}

.average-rating .stat-icon {
  background-color: rgba(255, 175, 64, 0.15);
  color: #ffaf40;
}

.latest-review .stat-icon {
  background-color: rgba(82, 113, 255, 0.15);
  color: #5271ff;
}

.stat-info {
  flex: 1;
}

.stat-title {
  font-size: 16px;
  color: #666;
  margin-bottom: 5px;
  font-weight: 500;
}

.stat-value {
  font-size: 32px;
  font-weight: 700;
  color: #333;
  display: flex;
  align-items: center;
}

.out-of {
  font-size: 16px;
  opacity: 0.7;
  margin-left: 5px;
}

.stat-details {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

/* Betygsfördelning */
.reviews-rating-distribution {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #eaeaea;
}

.reviews-rating-distribution h3 {
  font-size: 18px;
  color: #333;
  margin-top: 0;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
  font-weight: 600;
}

.rating-bars {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.rating-bar-item {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 30px;
}

.rating-label {
  width: 70px;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  color: #555;
  display: flex;
  align-items: center;
}

.rating-star {
  color: #ffd700;
  margin-right: 5px;
}

.rating-bar-container {
  flex: 1;
  height: 12px;
  background: #f5f5f5;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.rating-bar {
  height: 100%;
  transition: width 0.8s ease;
  border-radius: 6px;
}

.rating-5 {
  background: linear-gradient(to right, #4caf50, #8bc34a);
}

.rating-4 {
  background: linear-gradient(to right, #8bc34a, #cddc39);
}

.rating-3 {
  background: linear-gradient(to right, #ffeb3b, #ffc107);
}

.rating-2 {
  background: linear-gradient(to right, #ffc107, #ff9800);
}

.rating-1 {
  background: linear-gradient(to right, #ff9800, #f44336);
}

.rating-count {
  min-width: 40px;
  font-size: 15px;
  font-weight: 600;
  color: #333;
  text-align: right;
}

/* För betygsstatistiken på admin-sidan */
.admin-rating-summary {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .admin-rating-summary {
    grid-template-columns: repeat(3, 1fr);
  }
}

.admin-stat-card {
  background: linear-gradient(135deg, #3a6186, #89253e);
  color: white;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.admin-stat-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 1;
}

.admin-stat-card .stat-content {
  position: relative;
  z-index: 2;
}

.admin-stat-card .stat-title {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
  font-weight: 500;
}

.admin-stat-card .stat-value {
  font-size: 36px;
  font-weight: 700;
  color: white;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.admin-stat-card .stat-stars {
  margin-left: 10px;
  color: #ffd700;
}

.admin-stat-card .stat-footer {
  margin-top: auto;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
}

.total-reviews-card {
  background: linear-gradient(135deg, #4facfe, #00f2fe);
}

.average-rating-card {
  background: linear-gradient(135deg, #ffaf40, #ffcc33);
}

.latest-review-card {
  background: linear-gradient(135deg, #5271ff, #3755f9);
}

@media (max-width: 767px) {
  .admin-rating-summary {
    grid-template-columns: 1fr;
  }

  .admin-stat-card {
    margin-bottom: 15px;
  }
}

/* Recensionshantering */
.reviews-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.reviews-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}

.search-box {
  position: relative;
  min-width: 250px;
}

.search-box input {
  width: 100%;
  padding: 10px 15px;
  padding-right: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.search-box input:focus {
  outline: none;
  border-color: #4facfe;
  box-shadow: 0 0 0 3px rgba(79, 172, 254, 0.1);
}

.search-box svg {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  pointer-events: none;
}

.filter-controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter-label {
  font-size: 14px;
  color: #666;
}

.star-filter {
  display: flex;
  gap: 5px;
}

.star-filter button {
  background: none;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 6px 10px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  color: #555;
}

.star-filter button:hover {
  background: #f9f9f9;
  border-color: #ccc;
}

.star-filter button.active {
  background: #4facfe;
  color: white;
  border-color: #4facfe;
}

.refresh-button {
  background-color: white;
  border: 1px solid #e0e0e0;
  color: #555;
  border-radius: 8px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.refresh-button svg {
  margin-right: 8px;
}

.refresh-button:hover {
  background-color: #f9f9f9;
  border-color: #ccc;
}

.refresh-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.filtered-count {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
  padding: 8px 12px;
  background: #f5f7fa;
  border-radius: 6px;
  display: inline-block;
}

/* Recensionslista */
.reviews-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.review-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 20px;
  position: relative;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid #f0f0f0;
  overflow: hidden;
}

.review-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  border-radius: 4px 0 0 4px;
}

.high-rating::before {
  background: linear-gradient(to bottom, #4caf50, #8bc34a);
}

.medium-rating::before {
  background: linear-gradient(to bottom, #ffeb3b, #ff9800);
}

.low-rating::before {
  background: linear-gradient(to bottom, #ff9800, #f44336);
}

.review-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.review-name {
  font-weight: 600;
  font-size: 18px;
  color: #333;
  margin: 0;
  word-break: break-word;
  padding-right: 30px;
}

.review-rating {
  display: flex;
  white-space: nowrap;
}

.star {
  color: #ddd;
  font-size: 18px;
}

.star.filled {
  color: #ffc107;
}

.review-date {
  font-size: 14px;
  color: #888;
  margin: 5px 0 15px;
  display: flex;
  align-items: center;
}

.review-date svg {
  margin-right: 6px;
  color: #888;
}

.review-comment {
  color: #444;
  line-height: 1.6;
  margin: 0;
  font-size: 14px;
  overflow-wrap: break-word;
  white-space: pre-line;
}

.delete-review,
.review-delete-btn {
  background-color: rgba(244, 67, 54, 0.1);
  color: #f44336;
  border: none;
  padding: 0.5rem 0.8rem;
  border-radius: 6px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  transition: all 0.3s;
  z-index: 10;
  position: relative;
}

.delete-review:hover,
.review-delete-btn:hover {
  background-color: rgba(244, 67, 54, 0.2);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(244, 67, 54, 0.2);
}

.reviews-table tr:hover {
  background-color: #f1f3f5 !important;
}

.reviews-table .review-actions {
  position: relative;
  z-index: 5;
}

.no-reviews {
  text-align: center;
  padding: 40px 20px;
  background: #f9f9f9;
  border-radius: 12px;
  color: #888;
  font-style: italic;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.no-reviews svg {
  color: #ccc;
}

/* Rating-specifika kort */
.rating-5 .review-rating {
  color: #4caf50;
}

.rating-4 .review-rating {
  color: #8bc34a;
}

.rating-3 .review-rating {
  color: #ffeb3b;
}

.rating-2 .review-rating {
  color: #ff9800;
}

.rating-1 .review-rating {
  color: #f44336;
}

/* Responsiv design för mobiler */
@media (max-width: 768px) {
  .reviews-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .reviews-actions {
    flex-direction: column;
    align-items: stretch;
  }

  .filter-controls {
    flex-direction: column;
    align-items: flex-start;
  }

  .star-filter {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }

  .reviews-stats-card {
    flex-direction: column;
  }

  .stat-item {
    width: 100%;
  }

  .reviews-list {
    grid-template-columns: 1fr;
  }
}

/* AdminReviews.css */
.admin-stats-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.admin-stat-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.stat-title {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.stat-value {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
}

.stat-stars {
  margin-left: 10px;
  font-size: 18px;
}

.rating-distribution .rating-bars {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rating-bar-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.rating-label {
  width: 40px;
  font-size: 14px;
  text-align: right;
  color: #666;
}

.rating-bar-wrapper {
  flex-grow: 1;
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
}

.rating-bar {
  height: 100%;
  background-color: #4285f4;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.rating-count {
  width: 30px;
  font-size: 14px;
  color: #666;
}

.admin-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.search-container {
  position: relative;
  flex-grow: 1;
  max-width: 400px;
}

.admin-search {
  width: 100%;
  padding: 10px 15px 10px 40px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.clear-search {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 20px;
  color: #888;
  cursor: pointer;
}

.rating-filter {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-label {
  font-size: 14px;
  color: #666;
}

.rating-buttons {
  display: flex;
  gap: 5px;
}

.rating-buttons button {
  padding: 6px 12px;
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.rating-buttons button.active {
  background-color: #4285f4;
  color: white;
  border-color: #4285f4;
}

.refresh-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.refresh-button:hover {
  background-color: #e9e9e9;
}

.reviews-table-container {
  overflow-x: auto;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-bottom: 20px;
}

.reviews-table {
  width: 100%;
  border-collapse: collapse;
}

.reviews-table th {
  background-color: #f8f9fa !important;
  color: #304f74 !important;
  font-weight: 600 !important;
  padding: 1rem 1.2rem !important;
  text-align: left !important;
  border-bottom: 2px solid #eaeaea !important;
  position: sticky;
  top: 0;
  z-index: 1;
}

.reviews-table tr:nth-child(even) {
  background-color: #f8f9fa !important;
}

.reviews-table tr:hover {
  background-color: #f1f3f5 !important;
}

.reviews-table td {
  padding: 1rem 1.2rem !important;
  border-bottom: 1px solid #eaeaea;
  color: #444;
}

/* Betyg styling */
.rating-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.rating-5 {
  background-color: rgba(76, 175, 80, 0.1);
  color: #4caf50;
}

.rating-4 {
  background-color: rgba(139, 195, 74, 0.1);
  color: #8bc34a;
}

.rating-3 {
  background-color: rgba(255, 235, 59, 0.1);
  color: #ffc107;
}

.rating-2 {
  background-color: rgba(255, 152, 0, 0.1);
  color: #ff9800;
}

.rating-1 {
  background-color: rgba(244, 67, 54, 0.1);
  color: #f44336;
}

/* Stjärnor */
.admin-stars-container {
  display: inline-flex;
  align-items: center;
}

.admin-star {
  display: inline-block;
  font-size: 18px;
  margin-right: 2px;
  text-shadow: none;
}

.admin-star.filled,
.admin-star.filled span {
  color: #ffd700 !important;
  -webkit-text-fill-color: #ffd700 !important;
}

.admin-star.empty,
.admin-star.empty span {
  color: #d3d3d3 !important;
  -webkit-text-fill-color: #d3d3d3 !important;
}

/* Responsiv design */
@media (max-width: 992px) {
  .admin-reviews {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .admin-container {
    padding: 1.5rem;
  }

  .admin-filters {
    flex-direction: column;
    align-items: stretch;
  }

  .search-container {
    max-width: none;
  }

  .reviews-table th,
  .reviews-table td {
    padding: 0.75rem !important;
  }
}

@media (max-width: 576px) {
  .reviews-table {
    font-size: 0.9rem;
  }

  .delete-review,
  .review-delete-btn {
    padding: 0.4rem 0.6rem;
    font-size: 0.9rem;
  }
}
