.menu-page {
  padding: 40px 20px;
  min-height: 70vh;
  background-color: #f5f5f5; /* Ljusgrå bakgrund för att se området */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.menu-container {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  background-color: white; /* Vit bakgrund för containern */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 2.5em;
}

.category-filters {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.category-button {
  padding: 10px 20px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 16px;
}

.category-button:hover,
.category-button.active {
  background: #333;
  color: white;
  border-color: #333;
}

.menu-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  padding: 20px 0;
}

.menu-item {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.menu-item:hover {
  transform: translateY(-5px);
}

.item-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.menu-item:hover .item-image img {
  transform: scale(1.05);
}

.item-details {
  padding: 20px;
}

.item-details h3 {
  margin: 0 0 10px 0;
  color: #333;
  font-size: 1.4em;
}

.description {
  color: #666;
  margin: 10px 0;
  line-height: 1.5;
}

.item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.price {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

.weight {
  color: #666;
  font-size: 0.9em;
}

.loading {
  text-align: center;
  padding: 40px;
  font-size: 1.2em;
  color: #666;
}

.menu-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
}

/* Responsiv design */
@media (max-width: 1024px) {
  .menu-container {
    max-width: 800px;
  }
}

@media (max-width: 768px) {
  .menu-container {
    max-width: 600px;
  }

  .menu-page {
    padding: 20px 15px;
  }

  .menu-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .category-filters {
    padding: 0 10px;
  }

  .category-button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .item-image {
    height: 180px;
  }
}

@media (max-width: 480px) {
  .menu-container {
    max-width: 100%;
    padding: 15px;
  }

  .menu-page {
    padding: 15px 10px;
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  text-align: center;
}

.error-container {
  text-align: center;
  padding: 20px;
  color: #dc3545;
}

.error-container button {
  margin-top: 10px;
  padding: 8px 16px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.error-container button:hover {
  background: #0056b3;
}
