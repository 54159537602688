/* Global CSS */
:root {
  --primary-color: #ff6b6b;
  --primary-hover: #ff5252;
  --secondary-color: #6c757d;
  --secondary-hover: #5a6268;
  --dark-color: #343a40;
  --light-color: #f8f9fa;
  --text-color: #343a40;
  --text-muted: #6c757d;
  --border-color: #dee2e6;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden; /* Förhindra horisontell scrollning */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: #f5f5f5;
  line-height: 1.6;
}

html,
#root,
.App {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

button,
input,
select,
textarea {
  font-family: inherit;
}

ul {
  list-style: none;
}

/* Responsive */
@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}

/* Font Awesome */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");

/* Behåll endast regler för att förhindra modal-overlay */
body:not(.reviews-page) .review-modal-overlay,
body:not(.reviews-page) .modal-overlay,
body:not(.reviews-page) [class*="modal"],
body:not(.reviews-page) [class*="overlay"] {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  position: absolute !important;
  pointer-events: none !important;
  z-index: -9999 !important;
}

/* Säkerställ att hemsidan alltid kan interageras med */
body.home-page,
.home-page,
body:not(.reviews-page) {
  pointer-events: auto !important;
  overflow: auto !important;
}

/* Men tillåt modala fönster ENDAST i reviews-page */
body.reviews-page .review-modal-overlay,
body.reviews-page .modal-overlay,
body.modal-open.reviews-page .review-modal-overlay,
body.modal-open.reviews-page .modal-overlay {
  display: flex !important;
  visibility: visible !important;
  opacity: 1 !important;
  position: fixed !important;
  pointer-events: auto !important;
  z-index: 9999 !important;
}

/* Stilar för modal-open body - begränsa bara till när reviews-page också är satt */
body.modal-open.reviews-page {
  overflow: hidden !important; /* Förhindra scrollning när modal visas, men bara på recensionssidan */
}

/* Explicit stilar för hem-sidan för att säkerställa att den fungerar korrekt */
body.home-page {
  background-color: #f5f5f5; /* Standardbakgrund */
  min-height: 100vh;
  width: 100%;
  max-width: 100%;
  position: relative;
  z-index: 1;
}
