.admin-offers-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-offers-container h2 {
  margin-bottom: 20px;
  color: #fff;
}

.offer-form {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  display: grid;
  gap: 15px;
}

.offer-form input,
.offer-form textarea,
.offer-form select {
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 16px;
}

.offer-form input::placeholder,
.offer-form textarea::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.offer-form textarea {
  resize: vertical;
  min-height: 120px;
}

.date-input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.date-input label {
  color: #fff;
  font-size: 14px;
}

.image-upload {
  margin: 15px 0;
}

.image-upload p {
  color: #fff;
  margin-bottom: 10px;
}

.file-input {
  width: 100%;
  padding: 10px;
  border: 1px dashed rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.image-preview {
  margin-top: 15px;
  max-width: 300px;
  border-radius: 4px;
  overflow: hidden;
}

.image-preview img {
  width: 100%;
  display: block;
}

.submit-button {
  background-color: #ff6b35;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s;
}

.submit-button:hover:not(:disabled) {
  background-color: #ff8a5c;
}

.submit-button:disabled {
  background-color: #666;
  cursor: not-allowed;
}

.offers-list {
  margin-top: 40px;
}

.offers-list h3 {
  color: black;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.no-offers-message {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
}

.offers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.offer-item-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.offer-item-image {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.offer-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.offer-item-content {
  padding: 15px;
}

.offer-item-content h4 {
  margin: 0 0 10px 0;
  color: #fff;
  font-size: 1.2em;
}

.offer-item-description {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 10px;
  font-size: 0.9em;
  line-height: 1.4;
}

.offer-item-price {
  color: #ff6b35;
  font-weight: bold;
  margin: 5px 0;
}

.offer-item-date {
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.8em;
  margin: 5px 0 15px;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.delete-button:hover {
  background-color: #c82333;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #ff6b35;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-overlay p {
  color: white;
  margin-top: 20px;
  font-size: 1.2em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .offers-grid {
    grid-template-columns: 1fr;
  }

  .image-preview {
    max-width: 100%;
  }
}

.admin-offers {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.admin-offers h1,
.admin-offers h2,
.admin-offers h3,
.admin-offers .title,
.admin-offers .offer-title,
.admin-offers .page-title,
#admin-offers-container h1,
#admin-offers-container h2,
#admin-offers-container .title,
div[class*="admin"] h1,
div[class*="admin"] h2,
div[class*="admin"] .title,
div[class*="offer"] h1,
div[class*="offer"] h2,
div[class*="offer"] .title {
  color: #333 !important;
  text-shadow: none !important;
}

.admin-offers p,
.admin-offers span,
.admin-offers div,
#admin-offers-container p,
#admin-offers-container span,
#admin-offers-container div,
div[class*="admin"] p,
div[class*="admin"] span,
div[class*="admin"] div {
  color: #333 !important;
}

.admin-offers .offer-list .offer-item .title,
.admin-offers .offers-list .offer-item .title,
.admin-offers .offer-list .title,
.admin-offers .offers-list .title {
  color: #333 !important;
}

.admin-offers .offer-item .title {
  color: #333;
  font-weight: bold;
}

.admin-offers .section-title {
  color: #333;
}

.add-button {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 20px;
}

.add-button:hover {
  background-color: #2ecc71;
}

.offer-form-container {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px;
}

.offer-form-container h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.offer-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-row .form-group {
  flex: 1;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: 500;
}

.offer-form input[type="text"],
.offer-form input[type="number"],
.offer-form input[type="date"],
.offer-form textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.checkbox-label input {
  margin: 0;
}

.image-preview {
  margin-top: 10px;
  max-width: 300px;
}

.image-preview img {
  width: 100%;
  border-radius: 4px;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.cancel-button {
  background-color: #7f8c8d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.save-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #95a5a6;
}

.save-button:hover {
  background-color: #2980b9;
}

.offers-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.offer-item {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
}

.offer-item.inactive {
  background-color: #f8f8f8;
  opacity: 0.7;
}

.offer-details {
  display: flex;
  gap: 20px;
  flex: 1;
}

.offer-thumb {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 4px;
}

.offer-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.offer-info {
  flex: 1;
}

.offer-info h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.offer-info p {
  margin: 5px 0;
  color: #666;
}

.offer-price-admin {
  font-size: 1.1rem;
}

.offer-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.edit-button,
.delete-button {
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  min-width: 80px;
}

.edit-button {
  background-color: #3498db;
  color: white;
}

.delete-button {
  background-color: #e74c3c;
  color: white;
}

.edit-button:hover {
  background-color: #2980b9;
}

.delete-button:hover {
  background-color: #c0392b;
}

.loading,
.no-offers {
  padding: 20px;
  text-align: center;
  background-color: #f8f8f8;
  border-radius: 5px;
  margin-top: 20px;
  color: #666;
}

/* Responsiv design */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
    gap: 15px;
  }

  .offer-details {
    flex-direction: column;
  }

  .offer-thumb {
    width: 100%;
    height: 150px;
  }

  .offer-item {
    flex-direction: column;
    gap: 15px;
  }

  .offer-actions {
    flex-direction: row;
    justify-content: flex-end;
  }
}

/* Reset och förbättra layout för admin-recensionssidan */
.admin-reviews,
[class*="admin"],
.reviews-page {
  position: static !important;
  float: none !important;
  display: block !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  box-sizing: border-box !important;
  left: auto !important;
  right: auto !important;
  transform: none !important;
}

/* Återställ innehållsområdet */
.admin-content,
.content-area,
main[role="main"],
[class*="content"] {
  width: 100% !important;
  margin: 0 auto !important;
  padding: 20px !important;
}

/* Återställ statistikkort */
.admin-stat-card,
.stat-card,
[class*="stat"] {
  display: inline-block !important;
  width: calc(33.333% - 20px) !important;
  margin: 10px !important;
  vertical-align: top !important;
  box-sizing: border-box !important;
}

/* Tabellåterställning */
table {
  width: 100% !important;
  table-layout: auto !important;
  border-collapse: collapse !important;
}

/* Överskrid eventuella absoluta eller relativa positioneringar */
* {
  position: static !important;
  float: none !important;
}

/* Undanta element som måste behålla sin originella positionering */
input,
button,
select,
option,
a,
th,
td,
table {
  position: relative !important;
}

/* Rensa eventuella problematiska transforms */
body * {
  transform: none !important;
}

/* Grundläggande återställning och responsiv layout för admin-recensioner */
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Huvudinnehållsområde */
.admin-reviews {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

/* Rubriker */
.admin-reviews h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Statistikkort */
.admin-stat-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.admin-stat-card {
  flex: 1;
  min-width: 200px;
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.stat-title {
  font-size: 14px;
  margin-bottom: 5px;
}

.stat-value {
  font-size: 18px;
  font-weight: bold;
}

/* Stjärnor i normal storlek */
.star,
.admin-reviews .star {
  font-size: 16px;
  margin-right: 2px;
}

/* Sökning och filter */
.search-container {
  width: 100%;
  margin-bottom: 20px;
}

input[type="search"],
input[type="text"] {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

/* Recensionslista */
.reviews-list {
  width: 100%;
}

/* Tabell för recensioner */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 12px 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Knappar */
button {
  padding: 8px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

/* Betygsrelaterade element */
.betygsfordelning {
  width: 100%;
  margin-top: 20px;
}

/* Responsiva justeringar */
@media (max-width: 992px) {
  .admin-stat-cards {
    flex-direction: column;
  }

  .admin-stat-card {
    width: 100%;
  }

  table {
    font-size: 14px;
  }

  th,
  td {
    padding: 8px 4px;
  }
}

@media (max-width: 768px) {
  .admin-reviews {
    padding: 10px;
  }

  .admin-reviews h1 {
    font-size: 20px;
  }

  table {
    font-size: 12px;
  }

  /* Göm mindre viktiga kolumner på mobil */
  .hide-on-mobile {
    display: none;
  }
}

@media (max-width: 480px) {
  .admin-reviews h1 {
    font-size: 18px;
  }

  th,
  td {
    padding: 6px 2px;
  }

  button {
    padding: 6px 10px;
    font-size: 12px;
  }
}

/* Fixa eventuella överkompensationer */
* {
  box-sizing: border-box;
}

/* Återställ "Läs mer"-funktionalitet */
.read-more-button,
button[class*="read-more"],
.review-card button {
  pointer-events: auto !important;
  cursor: pointer !important;
  z-index: 10 !important;
}

/* Säkerställ att popup-fönstret visas korrekt */
.review-modal,
.popup-comment,
[class*="modal"],
[class*="popup"] {
  display: block;
  position: fixed;
  z-index: 1000;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Bakgrund för popup */
.modal-overlay,
.popup-overlay,
[class*="overlay"] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

/* Stängningsknapp för popup */
.close-modal,
.close-popup,
[class*="modal"] button[class*="close"],
[class*="popup"] button[class*="close"] {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
}
