.completions-list {
  margin-top: 20px;
}

.completions-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.completions-table th,
.completions-table td {
  padding: 12px 15px;
  text-align: left;
}

.completions-table th {
  background-color: #f5f5f5;
  font-weight: 600;
  color: #333;
  border-bottom: 1px solid #ddd;
}

.completions-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.completions-table tr:hover {
  background-color: #f0f0f0;
}

.completions-table td {
  border-bottom: 1px solid #eee;
}

.completion-name {
  font-weight: 500;
}

.completion-city {
  color: #555;
}

.completion-time {
  font-family: monospace;
}

.challenge-easy {
  color: #1565c0;
}

.challenge-medium {
  color: #f57f17;
}

.challenge-hard {
  color: #c62828;
}

.no-completions {
  padding: 20px;
  text-align: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  color: #666;
}

.completions-loading {
  text-align: center;
  padding: 20px;
  color: #888;
}

/* Nya stilar för rankning */
.rank-column {
  width: 50px;
  text-align: center;
}

.rank-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-weight: bold;
  color: white;
  margin: 0 auto;
  font-size: 0.85rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Tierkategorier (1-10) */
.tier-1 {
  background: linear-gradient(135deg, #ff9d00, #ff6b00);
}

.tier-2 {
  background: linear-gradient(135deg, #ff7e00, #ff5e00);
}

.tier-3 {
  background: linear-gradient(135deg, #ff5900, #ff4500);
}

.tier-4 {
  background: linear-gradient(135deg, #f44336, #d32f2f);
}

.tier-5 {
  background: linear-gradient(135deg, #e91e63, #c2185b);
}

.tier-6 {
  background: linear-gradient(135deg, #9c27b0, #7b1fa2);
}

.tier-7 {
  background: linear-gradient(135deg, #673ab7, #512da8);
}

.tier-8 {
  background: linear-gradient(135deg, #3f51b5, #303f9f);
}

.tier-9 {
  background: linear-gradient(135deg, #2196f3, #1976d2);
}

.tier-10 {
  background: linear-gradient(135deg, #03a9f4, #0288d1);
}

@media (max-width: 768px) {
  .completions-table th,
  .completions-table td {
    padding: 10px;
    font-size: 14px;
  }

  .completions-table {
    font-size: 14px;
  }

  .rank-badge {
    width: 26px;
    height: 26px;
    font-size: 0.75rem;
  }
}

@media (max-width: 576px) {
  .completions-list {
    overflow-x: auto;
    padding-bottom: 10px;
  }

  .completions-table {
    min-width: 500px;
  }

  .completions-table th,
  .completions-table td {
    padding: 8px;
    font-size: 13px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.completions-table tr {
  animation: fadeIn 0.3s ease-in-out forwards;
}

.completions-table tr:nth-child(1) {
  animation-delay: 0.05s;
}
.completions-table tr:nth-child(2) {
  animation-delay: 0.1s;
}
.completions-table tr:nth-child(3) {
  animation-delay: 0.15s;
}
.completions-table tr:nth-child(4) {
  animation-delay: 0.2s;
}
.completions-table tr:nth-child(5) {
  animation-delay: 0.25s;
}
.completions-table tr:nth-child(6) {
  animation-delay: 0.3s;
}
.completions-table tr:nth-child(7) {
  animation-delay: 0.35s;
}
.completions-table tr:nth-child(8) {
  animation-delay: 0.4s;
}
.completions-table tr:nth-child(9) {
  animation-delay: 0.45s;
}
.completions-table tr:nth-child(10) {
  animation-delay: 0.5s;
}
