.gallery-page {
  min-height: 80vh;
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.gallery-page .container {
  max-width: 1200px;
  margin: 0 auto;
}

.gallery-page .page-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #333;
  position: relative;
}

.gallery-page .page-title:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #f8a100;
}

.gallery-content {
  text-align: center;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
