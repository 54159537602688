.about-page {
  min-height: 80vh;
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.about-page .container {
  max-width: 1200px;
  margin: 0 auto;
}

.about-page .page-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #333;
  position: relative;
}

.about-page .page-title:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #f8a100;
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 50px;
}

.about-text {
  flex: 1;
  min-width: 300px;
}

.about-text h2 {
  color: #333;
  margin-bottom: 15px;
  font-size: 1.8rem;
}

.about-text p {
  margin-bottom: 30px;
  line-height: 1.7;
  color: #444;
}

.about-image {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.about-img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .about-content {
    flex-direction: column-reverse;
  }

  .about-image {
    margin-bottom: 30px;
  }

  .about-page .page-title {
    font-size: 2rem;
  }

  .about-text h2 {
    font-size: 1.5rem;
  }
}
