.footer {
  background-color: #000;
  color: #fff;
  padding: 50px 0 20px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-info {
  text-align: center;
  margin-bottom: 30px;
}

.footer-logo {
  margin-bottom: 15px;
}

.footer-logo a {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  text-decoration: none;
}

.footer-address p {
  margin: 5px 0;
  font-size: 14px;
}

/* Sociala medier */
.social-media {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.social-icon {
  font-size: 24px;
  color: #fff;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icon:hover {
  transform: translateY(-3px);
  color: #ccc;
}

/* Förenklade footer-länkar - Horisontellt utseende */
.footer-nav {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s ease;
  position: relative;
}

.footer-link:after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -3px;
  left: 0;
  background-color: #fff;
  transition: width 0.3s ease;
}

.footer-link:hover {
  color: #ccc;
}

.footer-link:hover:after {
  width: 100%;
}

/* Copyright */
.copyright {
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  color: #999;
}

/* Responsiv design */
@media (max-width: 768px) {
  .footer-nav {
    flex-wrap: wrap;
    gap: 15px;
  }

  .social-media {
    margin-bottom: 25px;
  }
}

@media (max-width: 480px) {
  .footer-nav {
    gap: 12px;
  }

  .footer-link {
    font-size: 13px;
  }
}
