/* Utmaningslayout */
.challenges-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
  margin-top: 20px;
}

@media (min-width: 992px) {
  .challenges-container {
    grid-template-columns: 300px 1fr;
  }
}

/* Utmaningsväljare */
.challenge-selector {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  padding: 20px;
  height: fit-content;
}

.challenge-selector h3 {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  display: flex;
  align-items: center;
}

.challenge-selector h3::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 18px;
  background: #4361ee;
  margin-right: 10px;
  border-radius: 2px;
}

.challenges-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.challenge-item {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 12px 15px;
  cursor: pointer;
  transition: all 0.2s;
  border-left: 3px solid transparent;
}

.challenge-item:hover {
  background: #f1f3f5;
  transform: translateX(3px);
}

.challenge-item.active {
  background: rgba(67, 97, 238, 0.05);
  border-left-color: #4361ee;
}

.challenge-name {
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.challenge-meta {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.challenge-difficulty {
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 12px;
  font-weight: 500;
}

.challenge-difficulty.lätt {
  background: rgba(76, 175, 80, 0.1);
  color: #4caf50;
}

.challenge-difficulty.medel {
  background: rgba(255, 152, 0, 0.1);
  color: #ff9800;
}

.challenge-difficulty.svår {
  background: rgba(244, 67, 54, 0.1);
  color: #f44336;
}

.challenge-completions {
  display: flex;
  align-items: center;
  color: #666;
}

.challenge-completions svg {
  margin-right: 4px;
}

.add-challenge-btn {
  margin-top: 15px;
  background: none;
  border: 1px dashed #ccc;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
}

.add-challenge-btn svg {
  margin-right: 8px;
}

.add-challenge-btn:hover {
  background: #f1f3f5;
  border-color: #aaa;
  color: #333;
}

/* Utmaningsdetaljer och tabell */
.challenge-completions {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.challenge-details {
  margin-bottom: 25px;
}

.challenge-details h3 {
  margin-top: 0;
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
}

.challenge-details p {
  color: #666;
  margin-bottom: 20px;
  line-height: 1.5;
}

.challenge-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
  justify-content: space-between;
}

.search-box {
  position: relative;
  min-width: 250px;
  flex: 1;
  max-width: 400px;
}

.search-box input {
  width: 100%;
  padding: 10px 15px;
  padding-right: 40px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.search-box input:focus {
  outline: none;
  border-color: #4361ee;
  box-shadow: 0 0 0 3px rgba(67, 97, 238, 0.1);
}

.search-box svg {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  pointer-events: none;
}

.add-completion-btn {
  display: flex;
  align-items: center;
  background-color: #4361ee;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.add-completion-btn svg {
  margin-right: 8px;
}

.add-completion-btn:hover {
  background-color: #3a56e3;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(67, 97, 238, 0.3);
}

.completion-count {
  font-size: 14px;
  color: #888;
  margin-bottom: 15px;
}

/* Tabell för slutföranden */
.completions-table-container {
  width: 100%;
  overflow-x: auto;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
}

.completions-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.completions-table th {
  background: #f8f9fa;
  padding: 12px 15px;
  text-align: left;
  font-weight: 600;
  color: #555;
  position: sticky;
  top: 0;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}

.completions-table th svg {
  margin-left: 5px;
  opacity: 0.3;
  transition: transform 0.2s, opacity 0.2s;
}

.completions-table th.asc svg {
  transform: rotate(180deg);
  opacity: 1;
}

.completions-table th.desc svg {
  opacity: 1;
}

.completions-table th:hover {
  background: #f1f3f5;
}

.completions-table td {
  padding: 12px 15px;
  border-top: 1px solid #f0f0f0;
  color: #444;
}

.completions-table tbody tr {
  transition: background-color 0.2s;
}

.completions-table tbody tr:hover {
  background-color: rgba(67, 97, 238, 0.03);
}

.completions-table .name-cell {
  font-weight: 500;
  color: #333;
}

.completions-table .time-cell {
  font-family: "Courier New", monospace;
  font-weight: 500;
  color: #4361ee;
}

.actions-cell {
  white-space: nowrap;
  text-align: right;
}

.actions-cell button {
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  margin-left: 5px;
}

.actions-cell .edit-btn {
  color: #4361ee;
}

.actions-cell .edit-btn:hover {
  background: rgba(67, 97, 238, 0.1);
}

.actions-cell .delete-btn {
  color: #f44336;
}

.actions-cell .delete-btn:hover {
  background: rgba(244, 67, 54, 0.1);
}

/* Inga slutföranden */
.no-completions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background: #f9f9fa;
  border-radius: 8px;
  text-align: center;
}

.no-completions svg {
  color: #ccc;
  margin-bottom: 15px;
}

.no-completions p {
  color: #888;
  font-style: italic;
  margin: 0;
}

/* Responsiv design */
@media (max-width: 768px) {
  .challenge-actions {
    flex-direction: column;
    align-items: stretch;
  }

  .search-box {
    max-width: none;
  }

  .completions-table th,
  .completions-table td {
    padding: 10px 12px;
  }

  .actions-cell button {
    width: 28px;
    height: 28px;
  }
}

/* Animation för sorteringspilar */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.completions-table th.asc svg,
.completions-table th.desc svg {
  animation: fadeIn 0.3s ease;
}

/* Hover-effekter för hela rader */
.completions-table tbody tr {
  position: relative;
}

.completions-table tbody tr::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: #4361ee;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.completions-table tbody tr:hover::after {
  transform: scaleX(1);
}

/* Tabs */
.challenges-tabs {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.challenges-tabs button {
  padding: 10px 20px;
  background: none;
  border: none;
  border-bottom: 3px solid transparent;
  font-size: 16px;
  cursor: pointer;
  color: #555;
  margin-right: 20px;
}

.challenges-tabs button.tab-active {
  color: #4285f4;
  border-bottom-color: #4285f4;
}

/* Completions Header */
.completions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.completions-header h3 {
  margin: 0;
  color: white;
}

.add-completion-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-completion-button:hover {
  background-color: #45a049;
}

/* Formulär */
.add-completion-form {
  background-color: #2d2d2d;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.add-completion-form h4 {
  margin-top: 0;
  margin-bottom: 20px;
  color: white;
  font-size: 18px;
}

.form-row {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 8px;
  font-weight: 500;
  color: #e0e0e0;
}

.form-group input,
.form-group select {
  padding: 10px 12px;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: #333;
  color: white;
  font-size: 15px;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #4285f4;
  box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.3);
}

.form-group input::placeholder {
  color: #888;
}

.form-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.submit-button {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.submit-button:hover {
  background-color: #3367d6;
}

.cancel-button {
  background-color: #555;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.cancel-button:hover {
  background-color: #444;
}

/* Tabell */
.completions-table-container {
  overflow-x: auto;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.completions-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #2d2d2d;
  color: white;
}

.completions-table th {
  position: sticky;
  top: 0;
  background-color: #222;
  padding: 12px 15px;
  text-align: left;
  font-weight: 600;
  color: white;
  border-bottom: 1px solid #444;
}

.completions-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #444;
}

.completions-table tr:hover {
  background-color: #333;
}

.challenge-easy {
  color: #64b5f6;
}

.challenge-medium {
  color: #ffd54f;
}

.challenge-hard {
  color: #e57373;
}

.delete-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 6px 10px;
  background-color: rgba(244, 67, 54, 0.2);
  color: #e57373;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.delete-button:hover {
  background-color: rgba(244, 67, 54, 0.3);
}

.no-completions {
  text-align: center;
  color: #aaa;
  padding: 30px 0;
}

/* Responsiv design */
@media (max-width: 992px) {
  .form-row {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .completions-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .add-completion-button {
    width: 100%;
    justify-content: center;
  }

  .completions-table th,
  .completions-table td {
    padding: 8px;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .completions-table {
    font-size: 13px;
  }
}

.admin-page {
  min-height: 100vh;
  background-color: #f8f9fa;
  font-family: 'Segoe UI', Roboto, -apple-system, BlinkMacSystemFont, sans-serif;
}

.admin-content {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
}

.admin-content h2 {
  color: #304f74;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.7rem;
}

.admin-content h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 3px;
  background: linear-gradient(to right, #3a6186, #4676b3);
  border-radius: 2px;
}

/* Tabellförbättringar */
.completions-table-container {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  background-color: white;
  margin-bottom: 2rem;
  border: 1px solid #eaeaea;
}

.completions-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.completions-table th {
  background-color: #f8f9fa !important;
  color: #304f74 !important;
  font-weight: 600 !important;
  padding: 14px 16px !important;
  text-align: left !important;
  border-bottom: 2px solid #eaeaea !important;
}

.completions-table tbody tr {
  background-color: #ffffff !important;
  transition: all 0.2s !important;
}

.completions-table tbody tr:nth-child(even) {
  background-color: #f8f9fa !important;
}

.completions-table tbody tr:hover {
  background-color: #f1f3f5 !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 10;
}

.completions-table td {
  padding: 14px 16px !important;
  border-bottom: 1px solid #eaeaea;
  color: #444;
}

/* Header för sektioner */
.completions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.completions-header h3 {
  margin: 0;
  color: #304f74;
  font-size: 1.5rem;
  position: relative;
}

.completions-header h3::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 50px;
  height: 3px;
  background: linear-gradient(135deg, #3a6186, #4676b3);
  border-radius: 2px;
}

.add-completion-button {
  background: linear-gradient(135deg, #3a6186, #4676b3);
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s;
}

.add-completion-button:hover {
  background: linear-gradient(135deg, #355a7d, #3f6aa1);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(58, 97, 134, 0.3);
}

/* Formulär */
.add-completion-form {
  background: white;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border: 1px solid #eaeaea;
}

.add-completion-form h4 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #304f74;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 2px solid rgba(58, 97, 134, 0.1);
  padding-bottom: 10px;
}
