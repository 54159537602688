.contact-page {
  min-height: 80vh;
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.contact-page .container {
  max-width: 1200px;
  margin: 0 auto;
}

.contact-page .page-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #333;
  position: relative;
}

.contact-page .page-title:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #f8a100;
}

.contact-content {
  margin-top: 50px;
}

.contact-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 50px;
}

.contact-card {
  background-color: white;
  padding: 30px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.contact-icon {
  font-size: 2.5rem;
  color: #f8a100;
  margin-bottom: 20px;
}

.contact-card h3 {
  margin-bottom: 15px;
  color: #333;
  font-size: 1.2rem;
}

.contact-card p {
  color: #666;
  line-height: 1.6;
}

.address-link,
.phone-link,
.email-link {
  color: #0077cc;
  text-decoration: none;
  transition: color 0.2s;
}

.address-link:hover,
.phone-link:hover,
.email-link:hover {
  color: #005599;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .contact-info {
    grid-template-columns: 1fr;
  }

  .contact-page .page-title {
    font-size: 2rem;
  }

  .contact-card {
    padding: 20px 15px;
  }
}
