.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: background-color 0.3s;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo img {
  height: 50px;
  width: auto;
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

.main-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.main-nav li {
  margin: 0 1rem;
}

.main-nav a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
  transition: color 0.3s;
  padding: 0.5rem 0;
  position: relative;
}

.main-nav a:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f8a100;
  transition: width 0.3s;
}

.main-nav a:hover {
  color: #f8a100;
}

.main-nav a:hover:after {
  width: 100%;
}

.nav-order-button {
  background-color: #f8a100;
  color: white !important;
  padding: 0.75rem 1.5rem !important;
  border-radius: 30px;
  transition: background-color 0.3s, transform 0.3s;
}

.nav-order-button:hover {
  background-color: #e69400;
  transform: translateY(-2px);
}

.nav-order-button:after {
  display: none;
}

@media (max-width: 768px) {
  .mobile-menu-button {
    display: block;
  }

  .main-nav {
    position: fixed;
    top: 73px;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
  }

  .main-nav.active {
    height: calc(100vh - 73px);
    overflow-y: auto;
  }

  .main-nav ul {
    flex-direction: column;
    padding: 2rem 0;
  }

  .main-nav li {
    margin: 1rem 0;
  }

  .order-button-container {
    margin-top: 1rem;
  }

  .nav.active {
    background-color: #fff;
  }

  .nav-link {
    color: #000;
  }

  /* Om du har en overlay när mobilmenyn är öppen */
  .menu-overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Behåll denna mörk för kontrast */
  }
}

/* Header.css - Svart navbar med vit text */

.site-header {
  background-color: #000;
  color: #fff;
  padding: 15px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  font-weight: 700;
  font-size: 1.8rem;
  color: #fff;
  text-decoration: none;
  letter-spacing: 1px;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 25px;
}

.nav-item {
  position: relative;
}

.nav-link {
  color: #000;
  font-weight: 500;
  text-decoration: none;
  padding: 5px 0;
  font-size: 1rem;
  transition: color 0.3s;
  position: relative;
}

.nav-link:hover {
  color: #555;
}

/* Animation för aktiva länkar */
.nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #fff;
  transition: width 0.3s;
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
}

/* Highlight för aktiv sida */
.nav-link.active {
  color: #000;
  border-bottom: 2px solid #000;
}

/* CTA knapp i navbar */
.nav-cta {
  background-color: #fff;
  color: #000;
  padding: 8px 20px;
  border-radius: 4px;
  font-weight: 600;
  transition: background-color 0.3s;
}

.nav-cta:hover {
  background-color: #f0f0f0;
  color: #000;
}

/* Mobilmeny-knapp */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Responsiv design */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .nav-links {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #000;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    transform: translateY(-100%);
    transition: transform 0.3s;
    z-index: 100;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  .nav-links.active {
    transform: translateY(0);
  }

  .nav-item {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }
}

/* Uppdatera bakgrundsfärgen på header/navbar */
.header {
  background-color: #ffffff !important;
  padding: 10px 0;
}

.header .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px 5px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Större centrerad logo */
.logo {
  text-align: center;
  margin: 0 0 5px 0;
  padding: 0;
}

.logo a {
  display: inline-block;
}

.logo-image {
  height: 120px; /* Mycket större logotyp */
  width: auto;
  display: block;
  transition: height 0.3s ease;
}

/* Navigeringslänkar under logotypen */
.nav {
  width: 100%;
  margin-top: 0;
}

.nav-list {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.nav-item {
  margin: 0 15px;
}

.nav-link {
  text-decoration: none;
  padding: 8px 0;
  font-weight: 500;
  position: relative;
  color: #000000 !important;
}

/* Understrykning vid hover och aktiv länk */
.nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transition: width 0.3s ease;
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
}

/* Mobilanpassningar */
@media (max-width: 992px) {
  .logo-image {
    height: 100px; /* Mindre på mellanstorleksskärmar */
  }
}

@media (max-width: 768px) {
  .header .container {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
  }

  .logo {
    margin-bottom: 0;
    text-align: left;
  }

  .logo-image {
    height: 70px; /* Mindre logotyp på mobil */
  }

  /* Övriga mobilstillar... */
}

@media (max-width: 480px) {
  .logo-image {
    height: 60px; /* Ännu mindre för mycket små skärmar */
  }

  .header .container {
    padding: 10px;
  }
}

/* Om du har en mobilmeny, uppdatera även dess färger */
.mobile-menu {
  background-color: #fff;
}

/* Om du har en hamburgerikon, uppdatera dess färg */
.mobile-menu-button .bar {
  background-color: #000;
}

/* Om logotypen behöver anpassas för vit bakgrund */
.logo a {
  color: #000;
}

/* För ikoner i navbaren */
.nav-icon {
  color: #000;
}

/* Om du använder SVG-ikoner direkt */
.header svg {
  fill: #000;
}

/* Dropdown-menyer */
.dropdown-menu {
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  color: #000;
}

.dropdown-item:hover {
  background-color: #f5f5f5; /* Ljusgrå bakgrund vid hover */
  color: #000;
}

/* Säkerställ att huvudnavbaren är helt vit */
.header,
header,
.navbar,
.nav-container,
nav,
.navigation {
  background-color: #ffffff !important; /* Ren vit med !important för att överskriva */
  border-bottom: 1px solid #e0e0e0 !important; /* Subtil gräns */
  color: #000000 !important; /* Svart text */
}

/* Alla länkar i navbaren ska ha svart text */
.header a,
.navbar a,
.nav a,
.nav-link,
.nav-item a,
header a,
.navigation a {
  color: #000000 !important; /* Svart text med !important */
  font-weight: 500 !important; /* Något fetare för bättre läsbarhet */
}

/* Hover-tillstånd för länkar */
.header a:hover,
.navbar a:hover,
.nav a:hover,
.nav-link:hover,
.nav-item a:hover,
header a:hover,
.navigation a:hover {
  color: #333333 !important; /* Mörkgrå vid hover */
}

/* Aktiva länkar */
.header a.active,
.navbar a.active,
.nav a.active,
.nav-link.active,
.nav-item a.active,
header a.active,
.navigation a.active {
  color: #000000 !important;
  border-bottom: 2px solid #000000 !important;
}

/* Logo */
.logo a,
.brand a,
.site-logo a,
.navbar-brand a {
  color: #000000 !important;
}

/* Mobileknapp (hamburgermeny) */
.mobile-menu-button,
.navbar-toggler,
.menu-toggle,
.hamburger {
  color: #000000 !important;
}

.mobile-menu-button .bar,
.navbar-toggler .bar,
.menu-toggle .bar,
.hamburger .bar,
.navbar-toggler-icon {
  background-color: #000000 !important;
}

/* Dropdown-menyer */
.dropdown-menu,
.submenu,
.nav-dropdown {
  background-color: #ffffff !important;
  border: 1px solid #e0e0e0 !important;
}

.dropdown-item,
.submenu-item,
.dropdown-link {
  color: #000000 !important;
}

.dropdown-item:hover,
.submenu-item:hover,
.dropdown-link:hover {
  background-color: #f5f5f5 !important;
  color: #000000 !important;
}

/* För mobilmenyn när den är öppen */
.nav.active,
.navbar.active,
.mobile-nav.active,
.mobile-menu.active {
  background-color: #ffffff !important;
}

/* Lämna inga chanser - fånga alla möjliga navbarelement */
nav,
nav *,
header,
header *,
.navbar,
.navbar * {
  background-color: #ffffff !important;
  color: #000000 !important;
}

/* Undantag för ikoner och knappar som ska ha svart färg */
nav svg,
header svg,
.navbar svg,
.nav-icon,
.header-icon {
  color: #000000 !important;
  fill: #000000 !important;
}

/* EXTREMT specifik selektor för att överskriva alla andra stilar */
header .logo img,
header .logo-image,
.header .logo img,
.header .logo-image,
.logo img,
img.logo-image,
header img[alt="Lilla Kiosken"],
.logo a img {
  height: 120px !important;
  width: auto !important;
  max-height: none !important;
  min-height: 120px !important;
  max-width: none !important;
  display: block !important;
  object-fit: contain !important;
}

/* Ännu mer specifika selektorer för responsivitet */
@media (max-width: 768px) {
  header .logo img,
  header .logo-image,
  .header .logo img,
  .header .logo-image,
  .logo img,
  img.logo-image,
  header img[alt="Lilla Kiosken"],
  .logo a img {
    height: 80px !important;
    min-height: 80px !important;
  }
}

/* Ultra-kompakt layout */
header .logo,
.header .logo {
  margin-bottom: 0 !important;
}

.nav-item {
  margin-top: 0 !important;
}

header .container > *,
.header .container > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* Justera specifikt avstånd mellan logo och nav */
.logo + .nav,
.logo ~ .nav {
  margin-top: 0 !important;
}

/* Minska linehöjden för navigering */
.nav-link {
  padding: 5px 0 !important;
  line-height: 1.2 !important;
}

.offers-link {
  background-color: #e74c3c;
  color: white !important;
  padding: 8px 15px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.offers-link:hover {
  background-color: #c0392b;
}

/* Grundläggande header-stilar */
.header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 10px 0;
}

.header .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  text-align: center;
  margin-bottom: 10px;
}

.logo-image {
  height: 120px;
  width: auto;
  display: block;
  margin: 0 auto;
}

.nav {
  width: 100%;
  display: block;
}

.nav-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.nav-item {
  margin: 0;
}

.nav-link {
  display: block;
  text-align: center;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #000 !important;
  text-decoration: none;
  transition: background-color 0.3s;
  min-width: 120px;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Dölj hamburger-meny */
.mobile-menu-button,
.mobile-menu-button .bar,
.navbar-toggler,
.menu-toggle,
.hamburger {
  display: none !important;
}

/* Mobilanpassningar */
@media (max-width: 600px) {
  .nav-list {
    flex-direction: column;
    gap: 5px;
  }

  .nav-item {
    width: 100%;
  }

  .nav-link {
    width: 100%;
    padding: 10px;
  }
}

@media (max-width: 992px) {
  .logo-image {
    height: 100px;
  }
}

@media (max-width: 768px) {
  .header .container {
    padding: 10px 20px;
  }

  .logo {
    margin-bottom: 10px;
  }

  .logo-image {
    height: 80px;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 5px;
  }

  .header .container {
    padding: 5px 10px;
  }

  .logo-image {
    height: 70px;
  }
}
