.challenges-page {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.challenges-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 15px;
  color: #333;
}

.challenges-intro {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 40px;
  font-size: 1.1rem;
  color: #555;
}

.challenges-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
}

.challenge-card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  background-color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.challenge-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.challenge-content {
  padding: 20px;
  flex-grow: 1;
}

.challenge-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #333;
}

.challenge-difficulty {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 30px;
  font-size: 0.85rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.easy-challenge .challenge-difficulty {
  background-color: #e3f2fd;
  color: #1565c0;
}

.medium-challenge .challenge-difficulty {
  background-color: #fff9c4;
  color: #f57f17;
}

.hard-challenge .challenge-difficulty {
  background-color: #ffebee;
  color: #c62828;
}

.challenge-description {
  color: #555;
  line-height: 1.6;
}

.challenge-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.challenge-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.challenge-card:hover .challenge-image img {
  transform: scale(1.05);
}

.no-challenges {
  grid-column: 1 / -1;
  text-align: center;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 8px;
  color: #666;
}

.wall-of-fame {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 40px;
}

.wall-of-fame h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 15px;
  color: #333;
}

.wall-of-fame p {
  text-align: center;
  max-width: 700px;
  margin: 0 auto 30px;
  color: #555;
}

.challenges-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .challenges-title {
    font-size: 2rem;
  }

  .challenges-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .challenges-title {
    font-size: 1.75rem;
  }

  .challenges-intro {
    font-size: 1rem;
  }

  .challenge-name {
    font-size: 1.25rem;
  }

  .challenges-grid {
    grid-template-columns: 1fr;
  }
}

.challenge-difficulty.hard {
  background: linear-gradient(45deg, #f44336, #ff5722);
}

.challenge-content {
  padding: 25px;
}

.challenge-content h2 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
  font-size: 20px;
}

.challenge-content p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

.participate-button {
  background: linear-gradient(45deg, #4361ee, #3a7bd5);
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  display: inline-block;
}

.participate-button:hover {
  background: linear-gradient(45deg, #3a56e3, #3169be);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(67, 97, 238, 0.3);
}

/* Wall of Fame Section */
.wall-of-fame {
  background: white;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  margin-bottom: 40px;
}

.wall-of-fame h2 {
  font-size: 24px;
  color: #2c3e50;
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

.wall-of-fame > p {
  text-align: center;
  color: #666;
  margin-bottom: 30px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.top-performers {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

.performer {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  background: #f8f9fa;
  transition: transform 0.2s;
}

.performer:hover {
  transform: translateX(5px);
  background: #f1f3f5;
}

.performer-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(45deg, #4361ee, #3a7bd5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.performer-avatar span {
  color: white;
  font-weight: 600;
  font-size: 18px;
}

.performer-info {
  flex: 1;
}

.performer-info h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.performer-info p {
  margin: 5px 0 0;
  color: #666;
  font-size: 14px;
}

.performer-badge {
  margin-left: 10px;
}

.performer-badge svg {
  color: #333;
}

.view-all-button {
  display: block;
  width: fit-content;
  margin: 0 auto;
  background: none;
  border: 2px solid #4361ee;
  color: #4361ee;
  border-radius: 6px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
}

.view-all-button:hover {
  background: rgba(67, 97, 238, 0.1);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .challenges-grid {
    grid-template-columns: 1fr;
  }

  .top-performers {
    gap: 10px;
  }

  .performer {
    padding: 12px;
  }

  .performer-avatar {
    width: 40px;
    height: 40px;
  }
}

.utmaningar-page {
  min-height: auto;
  display: flex;
  flex-direction: column;
  padding: 60px 20px 5px;
}

.content-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 60vh;
}

.wall-of-fame-container {
  width: 100%;
  max-width: 900px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 25px;
  margin-bottom: 1px;
  transform: translateX(-22%);
}

.wall-of-fame-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  font-size: 2rem;
}

@media (max-width: 768px) {
  .content-container {
    padding: 20px 15px;
  }

  .wall-of-fame-container {
    padding: 15px;
  }

  .wall-of-fame-title {
    font-size: 1.75rem;
  }
}
