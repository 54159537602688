/* Grundläggande stilar */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.App {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  font-family: "Arial", sans-serif;
  color: #333;
  background-color: #f5f5f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

/* Säkerställ att modal-overlay från recensionssidan inte syns på andra sidor */
body:not(.reviews-page) .review-modal-overlay,
body:not(.reviews-page) .modal-overlay,
body:not(.reviews-page) [class*="modal-overlay"],
body:not(.reviews-page) [class*="overlay"] {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  pointer-events: none !important;
  z-index: -9999 !important;
}

/* Säkerställ att hemsidan alltid kan klickas */
.home-page,
.home-container,
.hero-section,
body:not(.reviews-page),
html:not(.reviews-page) {
  pointer-events: auto !important;
}

/* Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (max-width: 1200px) {
  .container {
    padding: 0 2rem;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

/* Justera container för headern */
.header .container {
  max-width: 100%;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

@media (max-width: 992px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  .page-section {
    padding: 3rem 0;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 0 1rem;
  }

  .page-section {
    padding: 2rem 0;
  }
}

/* Stilar för länkar och knappar */
a {
  color: #000;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #555;
}

button {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

/* Hjälpklasser */
.text-center {
  text-align: center;
}

.mt-1 {
  margin-top: 0.5rem;
}
.mt-2 {
  margin-top: 1rem;
}
.mt-3 {
  margin-top: 1.5rem;
}
.mt-4 {
  margin-top: 2rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 1.5rem;
}
.mb-4 {
  margin-bottom: 2rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.3;
  color: #333;
}

p {
  margin-bottom: 1rem;
}

.page-section {
  padding: 4rem 0;
}

/* Admin app layout */
.admin-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.admin-nav {
  background-color: #000;
  padding: 15px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-brand {
  font-size: 20px;
  font-weight: bold;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 20px;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.nav-links li a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.admin-content {
  flex: 1;
  padding: 20px;
  background-color: #f5f5f5;
}

.logout-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.logout-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Knappar */
.btn,
button[type="submit"],
.submit-button {
  background-color: #000;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.btn:hover,
button[type="submit"]:hover,
.submit-button:hover {
  background-color: #333;
  transform: translateY(-2px);
}

/* Formulärelement */
input,
textarea,
select {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  transition: border-color 0.3s;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #000;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.hero-section {
  height: 500px;
  background-size: cover;
  background-position: center;
  position: relative;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100vw !important;
  margin-left: calc(-50vw + 50%) !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}

.hero-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Mörk överläggning för bättre läsbarhet */
}

.hero-content {
  position: relative;
  z-index: 1; /* Placerar innehållet ovanför överläggningen */
  max-width: 800px;
  padding: 0 20px;
}

.hero-content h1 {
  font-size: 3.5rem;
  margin-bottom: 15px;
  font-weight: 700;
}

.hero-content p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.cta-button {
  display: inline-block;
  background-color: #000;
  color: white;
  padding: 12px 30px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1.1rem;
  transition: transform 0.3s, background-color 0.3s;
  border: 2px solid white;
}

.cta-button:hover {
  background-color: white;
  color: #000;
  transform: translateY(-3px);
}

/* Ta bort eventuella containerbegränsningar i App.css */
.App,
html,
#root {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

/* Ta bort dessa redundanta regler i slutet av filen */
/* Säkerställ att hero-sektionen fyller hela bredden */
.home-container,
.hero-section,
.hero-section-container,
.fullwidth-section {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.banner-section {
  width: 100vw !important;
  margin-left: calc(-50vw + 50%) !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}
