/* Touch-friendly styles för mobila enheter */
@media (max-width: 768px) {
  /* Större tryckytor för knappar */
  button,
  .btn-primary,
  .btn-secondary,
  .category-filter,
  .nav-link,
  .nav-item,
  .quantity-btn {
    min-height: 44px;
    min-width: 44px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  /* Större avstånds mellan klickbara element */
  .nav-item,
  .form-group,
  .btn-add-to-cart {
    margin-bottom: 0.8rem;
  }

  /* Förbättrad scrollning */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  /* Förbättrad fokus-styling */
  a:focus,
  button:focus,
  input:focus,
  select:focus,
  textarea:focus {
    outline: 2px solid #ff6b6b;
    outline-offset: 2px;
  }
}
