/* Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Grundläggande stilar */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  line-height: 1.6;
}

/* Login-sida */
.admin-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.admin-login-card {
  width: 100%;
  max-width: 450px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.admin-login-header {
  text-align: center;
  margin-bottom: 30px;
}

.admin-login-header h1 {
  font-size: 28px;
  color: #333;
  margin-bottom: 8px;
}

.admin-login-header h2 {
  font-size: 18px;
  color: #666;
  font-weight: 500;
}

.admin-login-error {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

.admin-login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.admin-login-input-group {
  display: flex;
  flex-direction: column;
}

.admin-login-input-group label {
  font-size: 14px;
  color: #555;
  margin-bottom: 6px;
}

.admin-login-input-group input {
  padding: 12px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.2s;
}

.admin-login-input-group input:focus {
  border-color: #4285f4;
  outline: none;
  box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
}

.admin-login-button {
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 14px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 10px;
}

.admin-login-button:hover {
  background-color: #3367d6;
}

.admin-login-button:disabled {
  background-color: #a0c3ff;
  cursor: not-allowed;
}

.admin-login-footer {
  margin-top: 30px;
  text-align: center;
  color: #777;
  font-size: 14px;
}

@media (max-width: 500px) {
  .admin-login-card {
    padding: 20px;
  }

  .admin-login-button {
    padding: 12px;
  }
}

/* Felmeddelande */
.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

/* Footer */
.back-link {
  text-align: center;
  margin-top: 20px;
}

.back-link a {
  color: #4a90e2;
  text-decoration: none;
}

.back-link a:hover {
  text-decoration: underline;
}
