.admin-dashboard {
  font-family: "Segoe UI", Roboto, -apple-system, BlinkMacSystemFont, sans-serif;
  color: #333;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.admin-header {
  background: linear-gradient(135deg, #3a6186, #4676b3);
  color: white;
  padding: 1.5rem 2.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.admin-header::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><polygon fill="rgba(255,255,255,0.05)" points="0,0 100,0 100,20 0,60"/><polygon fill="rgba(255,255,255,0.05)" points="0,60 100,20 100,50 0,100"/></svg>');
  background-size: cover;
  opacity: 0.3;
}

.admin-header h1 {
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  position: relative;
}

.admin-header h1::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 60px;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 2px;
}

.admin-nav {
  margin-top: 1.5rem;
  position: relative;
}

.admin-nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 1.5rem;
}

.admin-nav li {
  margin: 0;
}

.admin-nav a {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 0;
  border-bottom: 2px solid transparent;
  transition: all 0.3s;
}

.admin-nav a:hover {
  color: white;
  border-bottom-color: rgba(255, 255, 255, 0.6);
}

.admin-nav a.active {
  color: white;
  border-bottom-color: white;
}

.logout-button {
  background: none;
  border: 2px solid rgba(255, 255, 255, 0.3);
  color: white;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1.2rem;
  border-radius: 2rem;
  transition: all 0.3s;
}

.logout-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: white;
}

.admin-content {
  padding: 2.5rem;
  max-width: 1400px;
  margin: 0 auto;
}

.admin-content h2 {
  margin-top: 0;
  color: #304f74;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.7rem;
}

.admin-content h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 3px;
  background: linear-gradient(to right, #3a6186, #4676b3);
  border-radius: 2px;
}

.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.8rem;
  margin-top: 2rem;
}

.dashboard-card {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  padding: 1.8rem;
  transition: all 0.3s;
  border: 1px solid #eaeaea;
  position: relative;
  overflow: hidden;
}

.dashboard-card:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  transform: translateY(-4px);
}

.dashboard-card h3 {
  margin-top: 0;
  color: #304f74;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.card-link {
  display: inline-flex;
  align-items: center;
  margin-top: 1.2rem;
  padding: 0.6rem 1.2rem;
  background: linear-gradient(135deg, #3a6186, #4676b3);
  color: white;
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.3s;
  font-weight: 500;
  gap: 0.5rem;
}

.card-link:hover {
  background: linear-gradient(135deg, #355a7d, #3f6aa1);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(58, 97, 134, 0.2);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #666;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(58, 97, 134, 0.2);
  border-top-color: #3a6186;
  border-radius: 50%;
  margin-right: 1rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Snyggare tabeller för admin */
.admin-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  background-color: white;
  margin-bottom: 2rem;
}

.admin-table th {
  background-color: #f8f9fa;
  color: #304f74;
  font-weight: 600;
  padding: 1rem 1.2rem;
  text-align: left;
  border-bottom: 2px solid #eaeaea;
  position: relative;
}

.admin-table td {
  padding: 1rem 1.2rem;
  border-bottom: 1px solid #eaeaea;
  color: #444;
  transition: background-color 0.2s;
}

.admin-table tbody tr:last-child td {
  border-bottom: none;
}

.admin-table tbody tr:hover td {
  background-color: #f8f9fa;
}

/* Responsiv tabell */
@media (max-width: 768px) {
  .admin-content {
    padding: 1.5rem;
  }

  .admin-table th,
  .admin-table td {
    padding: 0.8rem;
  }

  .dashboard-cards {
    grid-template-columns: 1fr;
  }
}
