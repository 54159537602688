/* Grundstil */
.home-container {
  max-width: 100%;
  font-family: "Montserrat", sans-serif;
  color: #333;
  position: relative;
}

.section-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.section-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  position: relative;
}

.section-title:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #f8a100;
}

/* Hero-sektion med fullbredd */
.hero-section {
  position: relative;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  height: 80vh;
  min-height: 600px;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  background-color: #000; /* Fallback om bilden inte laddar */
  box-sizing: border-box;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

.hero-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  padding: 25px;
  width: 90%;
}

.hero-section h1 {
  font-size: 2.8em;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.hero-section p {
  font-size: 1.3em;
  margin-bottom: 30px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  line-height: 1.5;
}

/* Beställningsknappar */
.order-button {
  display: inline-block;
  background-color: white;
  color: black;
  padding: 14px 28px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.order-button:hover {
  background-color: black;
  transform: translateY(-2px);
}

.secondary-button {
  display: inline-block;
  background-color: transparent;
  color: black;
  border: 2px solid black;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.secondary-button:hover {
  background-color: black;
  color: white;
}

/* Erbjudanden */
.offers-section {
  padding: 4rem 0;
  background-color: #ffffff;
}

.offers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.offer-card {
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
}

.offer-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.offer-image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  background-color: #f5f5f5;
}

.offer-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.offer-card:hover .offer-image img {
  transform: scale(1.05);
}

.offer-content {
  padding: 1.25rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.offer-content h3 {
  margin: 0 0 0.75rem;
  color: #333333;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.4;
}

.offer-description {
  color: #666666;
  margin-bottom: 1rem;
  line-height: 1.5;
  flex-grow: 1;
  font-size: 0.95rem;
}

.offer-price {
  font-size: 1.1rem;
  font-weight: 600;
  color: #e74c3c;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.price-label {
  color: #666666;
  font-weight: normal;
  font-size: 0.95rem;
}

.regular-price {
  color: #999999;
  font-size: 0.9rem;
  text-decoration: line-through;
  margin-left: 0.5rem;
}

.offer-validity {
  color: #666666;
  font-size: 0.85rem;
  margin-top: auto;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  min-height: 200px;
  background-color: #ffffff;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #e74c3c;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.no-offers {
  text-align: center;
  padding: 2rem;
  color: #666666;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.no-offers p {
  margin: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .offers-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .offer-image {
    height: 180px;
  }

  .offer-content {
    padding: 1rem;
  }

  .offer-content h3 {
    font-size: 1.1rem;
  }

  .offer-price {
    font-size: 1rem;
  }

  .offer-description {
    font-size: 0.9rem;
  }
}

/* Om Lilla Kiosken */
.about-section {
  padding: 60px 0;
  background-color: #f9f9f9;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 28px;
  color: black;
  position: relative;
}

.section-title:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 3px;
  background-color: white;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.about-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;
}

.about-text {
  flex: 1px;
  min-width: 300px;
  position: center;
}

.about-text p:first-of-type {
  color: #000000 !important; /* Black färg med vit bakgrund */
}

.about-text p {
  margin-bottom: 20px;
  line-height: 1.6;
  color: black;
  position: center;
}

.about-image {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
}

.about-img {
  border-radius: 15px;
  max-width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .about-content {
    flex-direction: column-reverse;
  }

  .about-image {
    margin-bottom: 30px;
  }
}

/* Galleri */
.gallery-section {
  padding: 60px 0;
  background-color: #f9f9f9;
}

.section-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 28px;
  color: black;
  position: relative;
}

.section-title:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 3px;
  background-color: #000;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.gallery-link {
  display: block;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.gallery-link:hover .gallery-image {
  transform: scale(1.05);
  filter: brightness(1.1);
}

.gallery-link:hover .gallery-caption {
  background-color: #f8a100;
  color: white;
}

.gallery-image {
  transition: transform 0.3s ease, filter 0.3s ease;
  display: block;
}

.gallery-item {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.gallery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.gallery-caption {
  padding: 15px;
  text-align: center;
  font-weight: 600;
  color: #000;
}

/* Information */
.info-section {
  background-color: #f9f9f9;
  padding: 4rem 0;
}

.info-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.info-card {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: transform 0.3s;
}

.info-card:hover {
  transform: translateY(-5px);
  color: black;
}

.info-icon {
  font-size: 2.5rem;
  color: gray;
  margin-bottom: 1rem;
}

.info-card h3 {
  margin-bottom: 1rem;
  color: #333;
}

.info-card p {
  color: #666;
  line-height: 1.6;
}

/* Call to action */
.cta-section {
  background-color: #ffffff;
  padding: 60px 20px;
  text-align: center;
}

.cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.cta-content h2 {
  color: #333;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.cta-content p {
  color: #666;
  font-size: 1.2em;
  margin-bottom: 30px;
}

/* Responsiv layout */
@media (max-width: 992px) {
  .about-content {
    flex-direction: column;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-content p {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .section-title {
    font-size: 1.75rem;
  }

  .hero-section {
    height: 70vh;
    min-height: 500px;
  }

  .hero-section h1 {
    font-size: 2.2rem;
  }

  .hero-section p {
    font-size: 1.1rem;
  }

  .hero-content {
    width: 95%;
    padding: 20px;
  }

  .order-button {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
  }

  .about-text p {
    font-size: 1rem;
  }

  .gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .testimonial-card .review-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }

  .testimonial-card .review-name {
    margin-bottom: 5px;
    max-width: 100%;
  }

  .testimonial-card .review-rating {
    margin-left: 0;
    margin-top: 0;
    text-align: left;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
    height: 16px;
    padding-bottom: 5px;
  }

  .update-reviews-container {
    margin-bottom: 20px;
  }

  .update-reviews-btn {
    width: 100%;
    max-width: 200px;
    padding: 12px 20px;
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 60vh;
    min-height: 400px;
  }

  .hero-section h1 {
    font-size: 1.8rem;
  }

  .hero-section p {
    font-size: 1rem;
  }

  .info-cards {
    grid-template-columns: 1fr;
  }

  .gallery-grid {
    grid-template-columns: 1fr;
  }
}

/* Testimonials (recensioner) sektion */
.testimonials-section {
  padding: 4rem 0;
  background-color: #f5f5f5;
}

.testimonials-section .section-title {
  margin-bottom: 3rem;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  width: 100%;
  margin-bottom: 2rem;
}

/* Testimonialkort */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.testimonial-card {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  opacity: 0; /* Börja med 0 opacity för animation */
}

.testimonial-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.testimonial-card .review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  flex-wrap: wrap;
}

.testimonial-card .review-name {
  flex: 1;
  max-width: 60%;
  color: #333;
  font-weight: bold;
  font-size: 1em;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-bottom: 0;
  padding-right: 10px;
}

.testimonial-card .review-rating {
  margin-left: auto;
  padding: 0;
  text-align: right;
  position: relative;
  right: auto;
  max-width: 50%;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  flex-shrink: 0;
  background-color: transparent;
  height: 16px !important;
  min-height: 16px !important;
  overflow: visible !important;
  line-height: 1 !important;
}

/* Fixera direkt stjärnstorlek för hemsidan */
.testimonial-card .review-rating span {
  font-size: 14px !important;
  display: inline-block !important;
  width: 14px !important;
  height: 14px !important;
  margin: 0 1px !important;
  line-height: 1 !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.testimonial-card .review-comment {
  color: #333;
  margin: 15px 0;
  line-height: 1.6;
  flex-grow: 1;
  overflow: visible;
  max-height: none;
  height: auto;
  white-space: normal;
  text-overflow: clip;
  display: block;
  padding-top: 5px;
}

.testimonial-card .review-comment.truncated {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.testimonial-card .review-date {
  color: #666;
  font-size: 0.9em;
  margin-top: 10px;
}

/* Visa mer knapp */
.testimonial-card .view-full-review-button {
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
  text-align: left;
}

.testimonial-card .view-more-button {
  display: inline-block;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  margin: 5px 0;
  width: auto;
  min-width: 80px;
  max-width: 100px;
  text-align: center;
  font-weight: 500;
  position: relative;
  z-index: 10;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.testimonial-card .view-more-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.testimonial-card .view-more-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* CTA-sektion */
.testimonials-cta {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
}

.btn-secondary {
  display: inline-block;
  background-color: transparent;
  color: #333;
  border: 2px solid #333;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-secondary:hover {
  background-color: #333;
  color: white;
}

.update-reviews-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
}

.update-reviews-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  font-size: 1rem;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3);
  position: relative;
  overflow: hidden;
  min-width: 140px;
}

.update-reviews-btn:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 123, 255, 0.4);
}

.update-reviews-btn:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.3);
}

.update-reviews-btn.refreshing {
  background-color: #0069d9;
  cursor: default;
  transform: none;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3);
  opacity: 0.8;
}

.update-reviews-btn:before {
  content: "↻";
  margin-right: 8px;
  font-size: 16px;
  transition: transform 0.3s ease;
  display: inline-block;
}

.update-reviews-btn:hover:before {
  transform: rotate(90deg);
}

.update-reviews-btn.refreshing:before {
  margin-right: 8px;
  display: inline-block;
  animation: spin 1s linear infinite;
}

.refresh-spinner {
  display: inline-block;
  font-size: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.address-link,
.phone-link {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s ease;
  display: inline-block;
  position: relative;
}

.address-link::after,
.phone-link::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.address-link:hover,
.phone-link:hover {
  color: #ff6b35; /* Eller annan färg som passar din design */
}

.address-link:hover::after,
.phone-link:hover::after {
  transform: scaleX(1);
}

/* För mobil - större träffyta för fingrar */
@media (max-width: 768px) {
  .address-link,
  .phone-link {
    padding: 5px 0;
  }

  /* Gör telefonlänken tydligare på mobilen */
  .phone-link {
    font-weight: 500; /* Något tjockare */
  }
}

/* Sponsor section styling */
.sponsor-section {
  background-color: #f0f0f0; /* Lite annorlunda bakgrund för att skilja sektionerna åt */
}

.sponsor-buttons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.social-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.social-button:hover {
  background-color: #c13584; /* Instagram färg */
  transform: translateY(-2px);
}

.hero-title {
  color: white !important;
}

/* Säkerställ att andra sektioner är kompakta */
.about-section,
.sponsor-section,
.info-section,
.cta-section,
.testimonials-section,
.gallery-section {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 60px 0;
}

/* Fullbreddshjälpklass */
.fullwidth-hero {
  position: relative;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  left: 0;
  right: 0;
  overflow-x: hidden;
}

/* Banner med fullbredd */
.banner-section {
  position: relative !important;
  width: 100vw !important;
  margin-left: calc(-50vw + 50%) !important;
  height: 550px !important;
  padding: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 30px !important;
  overflow: hidden !important;
  background-color: transparent !important; /* Ensure no background color */
}

.banner-img {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  object-position: center !important;
  z-index: 1 !important;
}

.banner-content {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  text-align: center !important;
  color: white !important;
  z-index: 5 !important; /* Higher z-index to ensure it's on top */
  width: 90% !important;
  max-width: 800px !important;
  padding: 0 !important;
  background-color: transparent !important; /* Remove the semi-transparent background */
}

.banner-content h1 {
  font-size: 2.5rem !important;
  margin-bottom: 1rem !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7) !important; /* Stronger text shadow for better readability */
  color: white !important;
}

.banner-content p {
  font-size: 1.2rem !important;
  margin-bottom: 1rem !important;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7) !important; /* Stronger text shadow for better readability */
  color: white !important;
}

.banner-button {
  display: inline-block !important;
  background-color: white !important;
  color: black !important;
  border: 2px solid black !important;
  font-weight: bold !important;
  padding: 0.75rem 1.5rem !important;
  border-radius: 50px !important;
  text-decoration: none !important;
  transition: all 0.3s ease !important;
  margin-top: 1rem !important;
  text-shadow: none !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
  font-size: 1.1rem !important;
}

.banner-button:hover {
  background-color: black !important;
  color: white !important;
  transform: translateY(-2px) !important;
}

/* Nya, enklare stjärnstiler */
.simple-stars-container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  height: auto;
  min-height: 20px;
  overflow: visible;
  margin: 0;
  padding: 0;
  width: 100%;
}

.simple-star {
  display: inline-block;
  font-size: 18px;
  margin: 0 2px;
  line-height: 1.2;
  font-family: Arial, sans-serif;
}

.simple-star.filled {
  color: #ffd700;
}

.simple-star.empty {
  color: #e0e0e0;
}

@media (max-width: 768px) {
  .simple-stars-container {
    justify-content: flex-start;
    margin-top: 2px;
  }

  .testimonial-card .review-rating {
    margin-left: 0;
    margin-top: 0;
    text-align: left;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
    height: auto;
    min-height: 25px;
    padding-bottom: 5px;
  }
}

/* Stjärnklasser för hemsidan */
.home-stars-container {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  height: 16px !important;
  overflow: visible !important;
  margin: 0 !important;
  padding: 0 !important;
}

.home-star {
  display: inline-block !important;
  font-size: 14px !important;
  margin: 0 1px !important;
  line-height: 1 !important;
  font-family: Arial, sans-serif !important;
  width: 14px !important;
  height: 14px !important;
  text-align: center !important;
}

.home-star.filled {
  color: #ffd700 !important; /* Ljusgul */
}

.home-star.empty {
  color: #d3d3d3 !important; /* Ljusgrå */
}

.testimonial-card .review-rating {
  margin-left: auto !important;
  padding: 0 !important;
  text-align: right !important;
  position: relative !important;
  right: auto !important;
  max-width: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  flex-shrink: 0 !important;
  background-color: transparent !important;
  height: 20px !important;
}

@media (max-width: 768px) {
  .home-stars-container {
    justify-content: flex-start !important;
    margin-top: 2px !important;
  }

  .testimonial-card .review-rating {
    margin-left: 0 !important;
    margin-top: 0 !important;
    text-align: left !important;
    width: 100% !important;
    max-width: 100% !important;
    justify-content: flex-start !important;
    height: 20px !important;
    padding-bottom: 5px !important;
  }
}

.loading-text {
  text-align: center;
  padding: 2rem;
  color: #666;
  font-size: 1.1rem;
}

/* Responsiv anpassning */
@media (max-width: 768px) {
  .testimonials-grid {
    grid-template-columns: 1fr;
  }

  .testimonial-card {
    padding: 15px;
  }

  .testimonial-card .review-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }

  .testimonial-card .review-name {
    margin-bottom: 5px;
    max-width: 100%;
  }

  .testimonial-card .review-rating {
    margin-left: 0;
    margin-top: 0;
    text-align: left;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start !important;
    height: 16px !important;
    min-height: 16px !important;
    padding-bottom: 5px;
  }

  .testimonials-cta {
    flex-direction: column;
    align-items: center;
  }

  .update-reviews-container {
    margin-bottom: 20px;
  }

  .update-reviews-btn {
    width: 100%;
    max-width: 200px;
    padding: 12px 20px;
    font-size: 0.95rem;
  }
}

/* Review Modal Overlay */
.review-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
}
