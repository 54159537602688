.login-page {
  min-height: 80vh;
  padding: 60px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}

.login-container {
  width: 100%;
  max-width: 450px;
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.1);
}

.login-title {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-size: 2rem;
}

.login-form {
  margin-bottom: 20px;
}

.login-error {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 14px;
  border-left: 4px solid #d32f2f;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 6px;
  color: #555;
  font-weight: 500;
}

.login-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  background-color: #f5f5f5;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.login-input:focus {
  border-color: #0077cc;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 119, 204, 0.25);
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: #0077cc;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.login-button:hover {
  background-color: #005fa3;
  transform: translateY(-2px);
}

.login-button:active {
  transform: translateY(0);
}

.login-info {
  text-align: center;
  color: #888;
  font-size: 14px;
  background-color: #f0f4f8;
  padding: 10px;
  border-radius: 4px;
  margin-top: 20px;
}

@media (max-width: 480px) {
  .login-container {
    padding: 20px;
  }

  .login-title {
    font-size: 1.8rem;
  }
}
